import React, { useContext, useEffect } from 'react';
import * as S from './BackButtonContainer.styles';
import { Builder } from '@builder.io/react';
import FunnelContext from '../../../context/FunnelContext';
import useLocalStorage from '../../../hooks/useLocalStorage';

const BackButtonContainer = props => {
  const {
    children,
    checkQueryParams,
    queryParamString,
    defaultLayer,
    triggerScrollToTop
  } = props;
  const { previousLayer, setPreviousLayer, setCurrentLayer } = useContext(
    FunnelContext
  );

  const [previousLayer2, setPreviousLayer2] = useLocalStorage(
    'previousLayer',
    checkQueryParams ? [defaultLayer || 'layer-0'] : []
  );
  const [, setCurrentLayer2] = useLocalStorage('currentLayer', 'layer-0');

  useEffect(() => {
    if (
      checkQueryParams &&
      [...previousLayer2, ...previousLayer].length === 0
    ) {
      setPreviousLayer([`layer-0`]);
      setPreviousLayer2([`layer-0`]);
    }
  }, [
    checkQueryParams,
    defaultLayer,
    previousLayer2,
    previousLayer,
    setPreviousLayer2,
    setPreviousLayer
  ]);

  const triggerClick = () => {
    const itemContainer = checkQueryParams
      ? [...previousLayer2]
      : [...previousLayer];
    const lastItem = itemContainer.pop();
    setPreviousLayer(itemContainer);
    setCurrentLayer(lastItem || `layer-0`);

    if (checkQueryParams) {
      setPreviousLayer2(itemContainer);
      setCurrentLayer2(lastItem || `layer-0`);

      const layerKey = queryParamString || 'layer';
      let params = new URLSearchParams(window.location.search);
      params.set(layerKey, lastItem);

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        params.toString();
      window.history.pushState({ path: newurl }, '', newurl);
    }

    if (triggerScrollToTop) {
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      });
    }
  };

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  if (previousLayer?.length > 0 || previousLayer2?.length > 0) {
    return (
      <S.Container
        onClick={triggerClick}
        layerId={`Back`}
        editing={Builder.isEditing}
      >
        {children || noChildren()}
      </S.Container>
    );
  } else {
    return ``;
  }
};

export default BackButtonContainer;
