import styled from 'styled-components';

export const Container = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  background: none;

  ${({ layerId, editing }) => {
    if (editing) {
      return `
        border: 1px dashed blue;

        &:before {
            content: "\\000AB ${layerId}";
            position:absolute;
            top: -24px;
            left: -54px;
            background: blue;
            color: white;
            font-size: 12px;
            padding: 5px 10px 3px;
            font-weight: bold;
            border-radius: 10px 10px 0 10px;
        }
        `;
    }
  }}
`;

export const NoChildren = styled.div`
  padding: 50px;
  text-align: center;
  font-style: italic;
  font-size: 12px;
`;
